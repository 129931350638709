import React from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import VisibilitySensor from "react-visibility-sensor";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import Button from "../components/common/button";
import NewsLetters from "../components/common/newsletter";
import Signature from "../components/about/signature";
import Seo from "../components/common/SEO";

export default function AboutMix({ data }) {
  const { sanityAboutUs } = data;
  const [startAnim, setStartAnim] = React.useState(false);

  function onChange(isVisible) {
    setStartAnim(isVisible);
  }
  return (
    <Layout>
      <Seo
        title={sanityAboutUs?.seo?.metaTitle}
        description={sanityAboutUs?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityAboutUs?.title}
              caption={sanityAboutUs?.caption}
            />
            <div className="pt-10">
              <GatsbyImage
                image={sanityAboutUs?.thumbnail?.asset?.gatsbyImageData}
                alt="About-mix"
              />
              <div className="text-justify">
                <p className="text-[17px] mt-[50px] leading-[27px] text-center font-primary text-black font-medium px-4 md:px-0">
                  {sanityAboutUs?.content}
                </p>
              </div>
              <div className="flex items-center justify-center">
                <VisibilitySensor onChange={onChange}>
                  <Signature play={startAnim} />
                </VisibilitySensor>
              </div>
              <div className="flex justify-center mt-6">
                <Button
                  text="SHOP NOW"
                  type="dark"
                  onClick={() => navigate("/products")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[50px]">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityAboutUs {
      title
      caption
      thumbnail {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      content
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
