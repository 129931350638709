import React, { memo } from "react";
import Lottie from "react-lottie-player";
import sigJson from "../../../assets/files/danielle-sig.json";

const Signature = memo(({ play }) => {
  return (
    <div>
      <Lottie
        animationData={sigJson}
        loop={false}
        // goTo={animationPosition}
        play={play}
        style={{
          width: 180,
          height: 180,
          alignSelf: "center",
        }}
      />
    </div>
  );
});

export default Signature;
